import axios from "axios"
import { api } from "../../config"
import { store } from "../../storeConfig/store.js"
import { loggedInUser } from "../auth/loginActions"

export const loadSuggestions = () => {
  return dispatch => {
    axios.get("/api/search/bookmarks/data").then(response => {
      dispatch({
        type: "MAYBE_UPDATE_SUGGESTIONS",
        suggestions: response.data.searchResult
      })
    })
  }
}

export const updateStarred = object => {
  return dispatch => {
    axios
      .post("api/update/bookmarks", {
        obj: object
      })
      .then(() => {
        dispatch({
          type: "UPDATE_STARRED",
          object
        })
        dispatch(loadSuggestions())
      })
  }
}

export const getCarts = () => {
  return dispatch => {
    api
      .get("/carts")
      .then((response) => {
        dispatch({
          type: "GET_DATA_CARTS_NAVBAR",
          object: response.data
        })
      })
      .catch(err => console.log(err))
  }
}

export const deleteCarts = id => {
  return dispatch => {
    api
      .delete("/carts/" + id)
      .then((response) => {
        // console.log(response.data);
        dispatch(getCarts())
        store.dispatch(loggedInUser())
      })
      .catch(err => console.log(err))
  }
}
