const initialState = {
  data: [],
  params: null,
  allData: [],
  totalPages: 0,
  filteredData: [],
  totalRecords: 0,
  sortIndex: []
}

const moveIndex = (arr, from, to) => {
  let el = arr[from]
  arr.splice(from, 1)
  arr.splice(to, 0, el)
}

const getIndex = (arr, arr2, arr3, params = {}) => {
  if (arr2.length > 0) {
    let startIndex = arr.findIndex(i => i.id === arr2[0].id) + 1
    let endIndex = arr.findIndex(i => i.id === arr2[arr2.length - 1].id) + 1
    let finalArr = [startIndex, endIndex]
    return (arr3 = finalArr)
  } else {
    let finalArr = [arr.length - parseInt(params.perPage), arr.length]
    return (arr3 = finalArr)
  }
}

const DataListReducer = (state = initialState, action) => {
  switch (action.type) {
    case "VENDOR_GET_DATA":{
      let total_items = state.allData.length
      let limit = action.params._limit
      let totalPages = Math.ceil(total_items/limit)
      return {
        ...state,
        data: action.data,
        totalPages: totalPages === 0 ? action.totalPages : totalPages,
        params: action.params,
        sortIndex: getIndex(
          state.allData,
          action.data,
          state.sortIndex,
          action.params
        )
      }
    }
    case "VENDOR_GET_ALL_DATA":{
      return {
        ...state,
        allData: action.data,
        totalRecords: action.data.length,
        sortIndex: getIndex(action.data, state.data, state.sortIndex)
      }
    }
    case "VENDOR_ADD_DATA":{
      let id = state.data.slice(-1)[0].id + 1
      state.data.push({
        ...action.data,
        id,
      })
      moveIndex(
        state.data,
        state.data.findIndex(item => item.id === id),
        0
      )
      return {
        ...state,
        data: state.data,
        totalRecords: state.allData.length,
        sortIndex: getIndex(state.allData, state.data, state.sortIndex)
      }
    }
    case "VENDOR_UPDATE_DATA":{
      state.data.find(item => {
        if (item.id === action.data.id) {
          return Object.assign(item, { ...action.data })
        } else {
          return item
        }
      })
      return { ...state }
    }
    case "VENDOR_DELETE_DATA":{
      let index = state.data.findIndex(item => item.id === action.obj.id)
      let updatedData = [...state.data]
      updatedData.splice(index, 1)
      return {
        ...state,
        data: updatedData,
        totalRecords: state.allData.length,
        sortIndex: getIndex(
          state.allData,
          state.data,
          state.sortIndex,
          state.params
        )
      }
    }
    case "VENDOR_GET_DATA_STOK_KURANG":{
      let total_items = state.allData.length
      let limit = action.params._limit
      let totalPages = Math.ceil(total_items/limit)
      return {
        ...state,
        data: action.data,
        totalPages: totalPages === 0 ? action.totalPages : totalPages,
        params: action.params,
        sortIndex: getIndex(
          state.allData,
          action.data,
          state.sortIndex,
          action.params
        )
      }
    }
    case "VENDOR_GET_ALL_DATA_STOK_KURANG":{
      return {
        ...state,
        allData: action.data,
        totalRecords: action.data.length,
        sortIndex: getIndex(action.data, state.data, state.sortIndex)
      }
    }
    default:
      return state
  }
}

export default DataListReducer
