function role() {
  const loggedInUser = JSON.parse(localStorage.getItem('loggedInUser'));

  if (loggedInUser) {
    if (loggedInUser.user.role.id === 3) {
      return 'vendor'
    } else {
      return 'apg'
    }
  }

  return '-'
}
export const login = (state = { userRole: role(), loggedInUser:{}, errorsLogin:{} }, action) => {
  switch (action.type) {
    case "LOGIN_WITH_EMAIL": {
      return { ...state, values: action.payload }
    }
    case "LOGIN_WITH_FB": {
      return { ...state, values: action.payload }
    }
    case "LOGIN_WITH_TWITTER": {
      return { ...state, values: action.payload }
    }
    case "LOGIN_WITH_GOOGLE": {
      return { ...state, values: action.payload }
    }
    case "LOGIN_WITH_GITHUB": {
      return { ...state, values: action.payload }
    }
    case "LOGIN_WITH_JWT": {
      return { ...state, values: action.payload }
    }
    case "LOGOUT_WITH_JWT": {
      return { ...state, values: action.payload }
    }
    case "LOGOUT_WITH_FIREBASE": {
      return { ...state, values: action.payload }
    }
    case "CHANGE_ROLE": {
      return { ...state, userRole: action.userRole }
    }
    case "LOGGED_IN_USER": {
      return { ...state, loggedInUser: action.user }
    }
    case "SET_ERROR_LOGIN": {
      return { ...state, errorsLogin: action.err }
    }
    default: {
      return state
    }
  }
}
