import _ from "lodash"
const initialState = {
  data: [],
  params: null,
  allData: [],
  totalPages: 0,
  filteredData: [],
  totalRecords: 0,
  sortIndex: [],
  kategori: [],
  subKategori: [],
  satuan: []
}

const determinePopularity = val => {
  if (val >= 75) return { popValue: val, color: "success" }
  else if (val < 75 && val >= 55) return { popValue: val, color: "primary" }
  else if (val < 55 && val >= 35) return { popValue: val, color: "warning" }
  else if (val < 35 && val >= 0) return { popValue: val, color: "danger" }
  else return { popValue: 0, color: "danger" }
}

const moveIndex = (arr, from, to) => {
  let el = arr[from]
  arr.splice(from, 1)
  arr.splice(to, 0, el)
}

const getIndex = (arr, arr2, arr3, params = {}) => {
  if (arr2.length > 0) {
    let startIndex = arr.findIndex(i => i.id === arr2[0].id) + 1
    let endIndex = arr.findIndex(i => i.id === arr2[arr2.length - 1].id) + 1
    let finalArr = [startIndex, endIndex]
    return (arr3 = finalArr)
  } else {
    let finalArr = [arr.length - parseInt(params.perPage), arr.length]
    return (arr3 = finalArr)
  }
}

const DataListReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_DATA":
      let total_items = state.allData.length
      let limit = action.params._limit
      let totalPages = Math.ceil(total_items/limit)
      return {
        ...state,
        data: action.data,
        totalPages: totalPages === 0 ? action.totalPages : totalPages,
        params: action.params,
        sortIndex: getIndex(
          state.allData,
          action.data,
          state.sortIndex,
          action.params
        )
      }
    case "GET_DATA_ITEM_BY_ID":
      return {
        ...state,
        dataById: action.data,
      }
    case "GET_ALL_DATA":
      return {
        ...state,
        allData: action.data,
        totalRecords: action.data.length,
        sortIndex: getIndex(action.data, state.data, state.sortIndex)
      }
    case "GET_ALL_DATA_KATEGORI":
      return {
        ...state,
        kategori:action.data,
        allData: action.data,
        totalRecords: action.data.length,
        sortIndex: getIndex(action.data, state.data, state.sortIndex)
      }
    case "GET_ALL_DATA_SUB_KATEGORI":
      return {
        ...state,
        subKategori: action.data,
        allData: action.data,
        totalRecords: action.data.length,
        sortIndex: getIndex(action.data, state.data, state.sortIndex)
      }
    case "GET_ALL_DATA_UNIT":
      return {
        ...state,
        satuan: action.data,
        allData: action.data,
        totalRecords: action.data.length,
        sortIndex: getIndex(action.data, state.data, state.sortIndex)
      }
    case "FILTER_DATA_ITEM":
      let filteredData = []
      let value = action.value
      if (value.length) {
        filteredData = state.allData
          .filter(item => {
            let startsWithCondition =
              item.nama_item.toLowerCase().startsWith(value.toLowerCase()) ||
              (!_.isEmpty(item.item_unit) ? item.item_unit.unit ? item.item_unit.unit.toLowerCase().startsWith(value.toLowerCase()) : false : false) ||
              (!_.isEmpty(item.item_category) ? item.item_category.category ? item.item_category.category.toLowerCase().startsWith(value.toLowerCase()) : false : false) ||
              (!_.isEmpty(item.item_sub_category) ? item.item_sub_category.sub_category ? item.item_sub_category.sub_category.toLowerCase().startsWith(value.toLowerCase()) : false : false) ||
              (item.tipe_khusus ? item.tipe_khusus.toLowerCase().startsWith(value.toLowerCase()) : false)

            let includesCondition =
              item.nama_item.toLowerCase().includes(value.toLowerCase()) ||
              (!_.isEmpty(item.item_unit) ? item.item_unit.unit ? item.item_unit.unit.toLowerCase().includes(value.toLowerCase()) : false : false) ||
              (!_.isEmpty(item.item_category) ? item.item_category.category ? item.item_category.category.toLowerCase().includes(value.toLowerCase()) : false : false) ||
              (!_.isEmpty(item.item_sub_category) ? item.item_sub_category.sub_category ? item.item_sub_category.sub_category.toLowerCase().includes(value.toLowerCase()) : false : false) ||
              (item.tipe_khusus ? item.tipe_khusus.toLowerCase().includes(value.toLowerCase()) : false)

            if (startsWithCondition) {
              return startsWithCondition
            } else if (!startsWithCondition && includesCondition) {
              return includesCondition
            } else return null
          })
          .slice(state.params.page - 1, state.params.perPage)
        return { ...state, filteredData }
      } else {
        filteredData = state.data
        return { ...state, filteredData }
      }
    case "FILTER_DATA_CATEGORY":{
      let filteredData = []
      let value = action.value
      if (value.length) {
        filteredData = state.allData
          .filter(item => {
            let startsWithCondition =
              item.category.toLowerCase().startsWith(value.toLowerCase())

            let includesCondition =
              item.category.toLowerCase().includes(value.toLowerCase())

            if (startsWithCondition) {
              return startsWithCondition
            } else if (!startsWithCondition && includesCondition) {
              return includesCondition
            } else return null
          })
          .slice(state.params.page - 1, state.params.perPage)
        return { ...state, filteredData }
      } else {
        filteredData = state.data
        return { ...state, filteredData }
      }}
    case "FILTER_DATA_SUB_CATEGORY":{
      let filteredData = []
      let value = action.value
      if (value.length) {
        filteredData = state.allData
          .filter(item => {
            let startsWithCondition =
              item.sub_category.toLowerCase().startsWith(value.toLowerCase())

            let includesCondition =
              item.sub_category.toLowerCase().includes(value.toLowerCase())

            if (startsWithCondition) {
              return startsWithCondition
            } else if (!startsWithCondition && includesCondition) {
              return includesCondition
            } else return null
          })
          .slice(state.params.page - 1, state.params.perPage)
        return { ...state, filteredData }
      } else {
        filteredData = state.data
        return { ...state, filteredData }
      }}
    case "FILTER_DATA_SATUAN":{
      let filteredData = []
      let value = action.value
      if (value.length) {
        filteredData = state.allData
          .filter(item => {
            let startsWithCondition =
              item.unit.toLowerCase().startsWith(value.toLowerCase())

            let includesCondition =
              item.unit.toLowerCase().includes(value.toLowerCase())

            if (startsWithCondition) {
              return startsWithCondition
            } else if (!startsWithCondition && includesCondition) {
              return includesCondition
            } else return null
          })
          .slice(state.params.page - 1, state.params.perPage)
        return { ...state, filteredData }
      } else {
        filteredData = state.data
        return { ...state, filteredData }
      }}
    case "ADD_DATA_LIST_APG":{
      let data = [...state.data]
      let allData = [...state.allData]
      data.push(action.obj)
      allData.push(action.obj)

      let total_items = allData.length
      let limit = action.params._limit
      let totalPages = Math.ceil(total_items/limit)
      return {
        ...state,
        allData: allData,
        data: data,
        totalRecords: allData.length,
        totalPages: totalPages,
        sortIndex: getIndex(allData, data, state.sortIndex)
      }
    }
    case "UPDATE_DATA_LIST_APG":{
      let data = [...state.data]
      let index = data.findIndex(obj => obj.id === action.obj.id)
      data[index] = action.obj
      return { ...state, data: data }
    }
    case "DELETE_DATA_LIST_APG":{
      let index = state.data.findIndex(item => item.id === action.obj.id)
      let updatedData = [...state.data]
      if (index !== -1) {
        updatedData.splice(index, 1)
      }

      let indexAllData = state.allData.findIndex(item => item.id === action.obj.id)
      let updatedAllData = [...state.allData]
      if (indexAllData !== -1) {
        updatedAllData.splice(indexAllData, 1)
      }

      let total_items = updatedAllData.length
      let limit = action.params._limit
      let totalPages = Math.ceil(total_items/limit)
      console.log('totalPages', total_items, limit, totalPages);
      return {
        ...state,
        data: updatedData,
        totalRecords: updatedAllData.length,
        totalPages: totalPages,
        sortIndex: getIndex(
          updatedAllData,
          updatedData,
          state.sortIndex,
          state.params
        )
      }
    }
    default:
      return state
  }
}

export default DataListReducer
