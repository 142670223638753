import React from "react"
import {
  NavItem,
  NavLink,
  UncontrolledDropdown,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Media,
  Badge
} from "reactstrap"
import PerfectScrollbar from "react-perfect-scrollbar"
import axios from "axios"
import * as Icon from "react-feather"
import classnames from "classnames"
import ReactCountryFlag from "react-country-flag"
import Autocomplete from "../../../components/@vuexy/autoComplete/AutoCompleteComponent"
import { useAuth0 } from "../../../authServices/auth0/auth0Service"
import { history } from "../../../history"
import { IntlContext } from "../../../utility/context/Internationalization"

const handleNavigation = (e, path) => {
  e.preventDefault()
  history.push(path)
}

const UserDropdown = props => {
  const { logout, isAuthenticated } = useAuth0()
  return (
    <DropdownMenu right>
      <DropdownItem
        tag="a"
        href="#"
        onClick={e => window.location.href=(props.userRole === 'vendor' ? `/vendor/ubah-profil` : `/app/user/edit/${props.loggedInUser.user.id}`)}
      >
        <Icon.User size={14} className="mr-50" />
        <span className="align-middle">Edit Profile</span>
      </DropdownItem>
      <DropdownItem divider />
      <DropdownItem
        tag="a"
        href="/pages/login"
        onClick={e => {
          e.preventDefault()
          localStorage.removeItem("loggedInUser")
          return props.logoutWithJWT()
        }}
      >
        <Icon.Power size={14} className="mr-50" />
        <span className="align-middle">Log Out</span>
      </DropdownItem>
    </DropdownMenu>
  )
}

class NavbarUser extends React.PureComponent {
  state = {
    navbarSearch: false,
    langDropdown: false,
    suggestions: []
  }

  componentDidMount() {
    axios.get("/api/main-search/data").then(({ data }) => {
      this.setState({ suggestions: data.searchResult })
    })
    this.props.getLoggedInUser()

    if (this.props.userRole !== "vendor") {
      this.props.getCarts()
    }
  }

  handleNavbarSearch = () => {
    this.setState({
      navbarSearch: !this.state.navbarSearch
    })
  }

  removeItem = id => {
    this.props.deleteCarts(id)
  }

  handleLangDropdown = () =>
    this.setState({ langDropdown: !this.state.langDropdown })

  render() {
    const renderCartItems = this.props.dataCarts.length ? this.props.dataCarts.map(item => {
      return (
        <div className="cart" key={item.vendor_id}>
          <div className="cart-header p-1 text-bold-700">{item.vendor}</div>

          {item.carts.map(cart => {
            return (
              <div className="cart-item" key={cart.id}>
                <Media
                  className="p-0"
                  onClick={() => history.push("/apg/katalog-detail/" + cart.id_item)}
                >
                  <Media className="text-center pr-0 mr-1" left>
                    <img
                      className="mt-1 pl-1 cart-item-img"
                      src={process.env.REACT_APP_URI_API + cart.gambar_item}
                      width="100"
                      alt="Cart Item"
                    />
                  </Media>
                  <Media className="overflow-hidden pr-1 py-1 pl-0" body>
                    <span className="item-title text-truncate text-bold-500 d-block mb-50">
                      {cart.nama_item}
                    </span>
                    {/* <span className="item-desc font-small-2 text-truncate d-block">
                      {cart.desc}
                    </span> */}
                    <div className="d-flex justify-content-between align-items-center mt-1">
                      <span className="align-middle d-block">{cart.jumlah_item} x {new Intl.NumberFormat('id-ID', {currency: 'IDR', style: 'currency'}).format(cart.harga_satuan_item)}</span>
                      <Icon.X
                        className="danger"
                        size={15}
                        onClick={e => {
                          e.stopPropagation()
                          this.removeItem(cart.id)
                        }}
                      />
                    </div>
                  </Media>
                </Media>
              </div>
            )
          })}
        </div>
      )
    }) : ''

    return (
      <ul className="nav navbar-nav navbar-nav-user float-right">
        {this.props.userRole !== 'vendor' ? (
          <UncontrolledDropdown
            tag="li"
            className="dropdown-notification nav-item"
          >
            <DropdownToggle tag="a" className="nav-link position-relative">
              <Icon.ShoppingCart size={21} />
              {this.props.userCartCount ? (
                <Badge pill color="primary" className="badge-up">
                  {this.props.userCartCount}
                </Badge>
              ) : null}
            </DropdownToggle>
            <DropdownMenu
              tag="ul"
              right
              className={`dropdown-menu-media dropdown-cart ${
                this.props.userCartCount === 0 ? "empty-cart" : ""
              }`}
            >
              <li
                className={`dropdown-menu-header ${
                  this.props.userCartCount === 0 ? "d-none" : "d-block"
                }`}
              >
                <div className="dropdown-header m-0">
                  <h3 className="white">
                    {this.props.userCartCount} Items
                  </h3>
                  <span className="notification-title">In Your Cart</span>
                </div>
              </li>
              <PerfectScrollbar
                className="media-list overflow-hidden position-relative"
                options={{
                  wheelPropagation: false
                }}
              >
                {renderCartItems}
              </PerfectScrollbar>
              <li
                className={`dropdown-menu-footer border-top ${
                  this.props.userCartCount === 0 ? "d-none" : "d-block"
                }`}
              >
                <div
                  className="dropdown-item p-1 text-center text-primary"
                  onClick={() => history.push("/apg/checkout")}
                >
                  <Icon.ShoppingCart size={15} />
                  <span className="align-middle text-bold-600 ml-50">
                    Checkout
                  </span>
                </div>
              </li>
              <li
                className={`empty-cart ${
                  this.props.userCartCount > 0 ? "d-none" : "d-block"
                } p-2`}
              >
                Your Cart Is Empty
              </li>
            </DropdownMenu>
          </UncontrolledDropdown>
        ) : null}
        <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
          <DropdownToggle tag="a" className="nav-link dropdown-user-link">
            <div className="user-nav d-sm-flex d-none">
              <span className="user-name text-bold-600">
                {this.props.userName}
              </span>
            </div>
            <span data-tour="user">
              <img
                src={this.props.userImg}
                className="round"
                height="40"
                width="40"
                alt="avatar"
              />
            </span>
          </DropdownToggle>
          <UserDropdown {...this.props} />
        </UncontrolledDropdown>
      </ul>
    )
  }
}
export default NavbarUser
