const initialState = {
  paymentTypes: [],
  paymentBanks: [],
  paymentPeriods: [],
  po: null
}

const penggunaReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CHECKOUT_PAYMENT_TYPES":
      return {
        ...state,
        paymentTypes: action.object,
      }
    case "CHECKOUT_PAYMENT_BANKS":
      return {
        ...state,
        paymentBanks: action.object,
      }
    case "CHECKOUT_PAYMENT_PERIODS":
      return {
        ...state,
        paymentPeriods: action.object,
      }
    case "CHECKOUT_GENERATE_PO":
      return {
        ...state,
        po: action.object,
      }

    default:
      return state
  }
}

export default penggunaReducer
