import { createBrowserHistory } from "history";
import { store } from "../src/redux/storeConfig/store.js"
import { loggedInUser } from "../src/redux/actions/auth/loginActions"
import { getCarts } from "../src/redux/actions/navbar/Index"

const history = createBrowserHistory({ basename: "" });

history.listen((location) => {
  if (!["/pages/login", "/pages/forgot-password", "/pages/register"].includes(location.pathname) && !location.pathname.includes("/vendor/")) {
    store.dispatch(loggedInUser(location))
    store.dispatch(getCarts(location))
  }
})

export { history }
