import _ from "lodash"
const initialState = {
  data: [],
  roles:[],
  vendors:[],
  vendor:[],
  provinsi: [],
  kota: [],
  vendorIndustri: [],
  vendorKelas: [],
  vendorSBU: []
}

const DataListReducer = (state = initialState, action) => {
  switch (action.type) {
    case "PENGGUNA_GET_DATA":{
      return {...state, data: action.data}
    }
    case "PENGGUNA_GET_ROLES":{
      return {...state, roles: action.data}
    }
    case "PENGGUNA_GET_VENDORS":{
      let vendors = [...state.vendors]
      if (action.data.length) {
        vendors= _.unionBy(state.vendors, action.data, 'id')
      }
      return {...state, vendors: vendors}
    }
    case "PENGGUNA_UPLOAD_AVA":{
      return {
        ...state,
        data: {
          ...state.data,
          avatar: action.data[0]
        }
      }
    }
    case "PENGGUNA_DELETE_AVA":{
      return {
        ...state,
        data: {
          ...state.data,
          avatar: null
        }
      }
    }
    case "PENGGUNA_GET_DATA_VENDOR_BY_ID":{
      return {...state, vendor: action.data}
    }
    case "PENGGUNA_ADD_DATA_VENDOR":{
      let vendors = _.unionBy(state.vendors, [action.data], 'id')

      return {
        ...state,
        vendor: action.data,
        vendors: vendors
      }
    }
    case "PENGGUNA_UPDATE_VENDOR":{
      return {...state, vendor: action.data}
    }
    default:
      return state
  }
}

export default DataListReducer
