import React from "react"
import { Navbar } from "reactstrap"
import { connect } from "react-redux"
import classnames from "classnames"
import { useAuth0 } from "../../../authServices/auth0/auth0Service"
import {
  logoutWithJWT,
  logoutWithFirebase,
  loggedInUser as getLoggedInUser
} from "../../../redux/actions/auth/loginActions"
import {
  getCarts,
  deleteCarts
} from "../../../redux/actions/navbar/Index"
import NavbarBookmarks from "./NavbarBookmarks"
import NavbarUser from "./NavbarUser"
import userImgApg from "../../../assets/img/portrait/small/avatar-s-11.jpg"
import userImgVendor from "../../../assets/img/portrait/small/avatar-vendor-default-big.png"

const UserName = props => {
  let username = ""
  if(props.loggedInUser){
    username = props.loggedInUser.user.username
  } else {
    username = "Dummy"
  }
  // if (props.userdata !== undefined) {
  //   username = props.userdata.name
  // } else if (props.user.login.values !== undefined) {
  //   username = props.user.login.values.name
  //   if (
  //     props.user.login.values.loggedInWith !== undefined &&
  //     props.user.login.values.loggedInWith === "jwt"
  //   ) {
  //     username = props.user.login.values.loggedInUser.name
  //   }
  // } else {
  //   username = "John Doe"
  // }

  return username
}
const ThemeNavbar = props => {
  const loggedInUser = JSON.parse(localStorage.getItem('loggedInUser'))
  const { user } = useAuth0()
  const colorsArr = [ "primary", "danger", "success", "info", "warning", "dark"]
  const navbarTypes = ["floating" , "static" , "sticky" , "hidden"]
  return (
    <React.Fragment>
      <div className="content-overlay" />
      <div className="header-navbar-shadow" />
      <Navbar
        className={classnames(
          "header-navbar navbar-expand-lg navbar navbar-with-menu navbar-shadow",
          {
            "navbar-light": props.navbarColor === "default" || !colorsArr.includes(props.navbarColor),
            "navbar-dark": colorsArr.includes(props.navbarColor),
            "bg-primary":
              props.navbarColor === "primary" && props.navbarType !== "static",
            "bg-danger":
              props.navbarColor === "danger" && props.navbarType !== "static",
            "bg-success":
              props.navbarColor === "success" && props.navbarType !== "static",
            "bg-info":
              props.navbarColor === "info" && props.navbarType !== "static",
            "bg-warning":
              props.navbarColor === "warning" && props.navbarType !== "static",
            "bg-dark":
              props.navbarColor === "dark" && props.navbarType !== "static",
            "d-none": props.navbarType === "hidden" && !props.horizontal,
            "floating-nav":
              (props.navbarType === "floating" && !props.horizontal) || (!navbarTypes.includes(props.navbarType) && !props.horizontal),
            "navbar-static-top":
              props.navbarType === "static" && !props.horizontal,
            "fixed-top": props.navbarType === "sticky" || props.horizontal,
            "scrolling": props.horizontal && props.scrolling

          }
        )}
      >
        <div className="navbar-wrapper">
          <div className="navbar-container content">
            <div
              className="navbar-collapse d-flex justify-content-between align-items-center"
              id="navbar-mobile"
            >
              <div className="bookmark-wrapper">
                {/* <NavbarBookmarks
                  sidebarVisibility={props.sidebarVisibility}
                  handleAppOverlay={props.handleAppOverlay}
                /> */}
              </div>
              {props.horizontal ? (
                <div className="logo d-flex align-items-center">
                  <div className="brand-logo mr-50"></div>
                  <h2 className="text-primary brand-text mb-0">APG</h2>
                </div>
              ) : null}
              <NavbarUser
                handleAppOverlay={props.handleAppOverlay}
                changeCurrentLang={props.changeCurrentLang}
                userName={<UserName userdata={user} loggedInUser={loggedInUser} {...props} />}
                userImg={
                  loggedInUser && loggedInUser.user.avatar && loggedInUser.user.avatar.formats
                  ? process.env.REACT_APP_URI_API + loggedInUser.user.avatar.formats.thumbnail.url
                  : props.user.login.values !== undefined &&
                    props.user.login.values.loggedInWith !== "jwt" &&
                    props.user.login.values.photoUrl
                      ? props.user.login.values.photoUrl
                      : user !== undefined && user.picture ? user.picture
                      : props.user.login.userRole === "vendor"
                        ? userImgVendor : userImgApg
                }
                loggedInUser={loggedInUser}
                userRole={props.user.login.userRole}
                userCartCount={props.user.login.loggedInUser.carts !== undefined ? props.user.login.loggedInUser.carts : '0'}
                getLoggedInUser={props.getLoggedInUser}
                getCarts={props.getCarts}
                deleteCarts={props.deleteCarts}
                dataCarts={props.dataCarts}
                loggedInWith={
                  props.user !== undefined &&
                  props.user.login.values !== undefined
                    ? props.user.login.values.loggedInWith
                    : null
                }
                logoutWithJWT={props.logoutWithJWT}
                logoutWithFirebase={props.logoutWithFirebase}
              />
            </div>
          </div>
        </div>
      </Navbar>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  return {
    user: state.auth,
    dataCarts: state.navbar.dataCarts
  }
}

export default connect(mapStateToProps, {
  logoutWithJWT,
  logoutWithFirebase,
  useAuth0,
  getLoggedInUser,
  getCarts,
  deleteCarts
})(ThemeNavbar)
