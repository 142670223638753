import _ from "lodash"
const initialState = {
  roles:[],
  permissions:{},
  users:[],
  role:[]
}

const DataListReducer = (state = initialState, action) => {
  switch (action.type) {
    case "HAK_AKSES_GET_ROLES":{
      return {...state, roles: action.data}
    }

    case "HAK_AKSES_GET_PERMISSIONS":{
      return {...state, permissions: action.data}
    }

    case "HAK_AKSES_GET_USERS":{
      return {...state, users: action.data}
    }

    case "HAK_AKSES_GET_ROLE_PERMISSIONS":{
      return {...state, role: action.data}
    }

    default:
      return state
  }
}

export default DataListReducer
